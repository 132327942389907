import React from 'react';
import { getFile } from '@sanity/asset-utils';
import sanityClient from '../../client';
import './Video.scss';

const Video = (props) => {
    const file = getFile(props, sanityClient.config());
    return (
        <div className='Video'>
            <video autoPlay muted loop>
                <source src={file.asset.url} />
                Your browser does not support the video tag.
            </video>
        </div>)
}

export default Video;