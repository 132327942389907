import React from "react";
import CallToAction from "../CallToAction/CallToAction";
import Image from "../Image/Image";
import ImageUrlBuilder from "@sanity/image-url";
import sanityClient from '../../client';
import './Hero.scss';

const Hero = ({ content }) => {
    const builder = ImageUrlBuilder(sanityClient);

    function urlFor(source) {
        return builder.image(source);
    }

    return (
        <div
            className={`Hero ${content.smallHero ? 'Hero--small' : ''}`}
            style={content.backgroundImage ? { backgroundImage: `url(${urlFor(content.img).width().url()})` } : {}}
        >
            <div className="Hero__innerWrapper">
                {!content.backgroundImage && <div className="Hero__image"><Image {...content.img} /></div>}
                <div className="Hero__content">
                    <h1>
                        <span>{content.subHeading}</span>
                        {content.heading}
                    </h1>
                    {!!content.buttonAction && <CallToAction {...content.buttonAction} />}
                </div>
            </div>
        </div>
    )
}
export default Hero;