import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, createPath, useLocation } from 'react-router-dom';
import sanityClient from './client';
import Header from './components/Header/Header';
import Page from './components/Page/Page';
import Footer from './components/Footer/Footer';
import './App.scss';

const App = () => {
  const [links, setLinks] = useState([]);
  const [startpage, setStartpage] = useState(null);

  useEffect(() => {
    sanityClient.fetch(
      `*[_type == 'page' && !(_id in path('drafts.**'))]{
        _id,
        pageName,
        pathName,
        "startpage": _id == (*[_type == "siteSettings"].startpage._ref)[0]
      }`
    )
      .then(pages => {
        setLinks(pages.filter(page => {
          if (page.startpage) {
            setStartpage(page._id);
            return false;
          }
          return true;
        }))
      })
      .catch(error => console.error(error));
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Header links={links} />
        <Routes>
          <Route path="/" element={<Page page={startpage} />} />
          {links.map(link => <Route key={link._id} path={createPath({ pathname: link.pathName })} element={<Page page={link._id} />} />)}
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  )
};

export default App;
