import React, { useEffect } from "react";
import { useState } from "react";
import sanityClient from "../../client";
import { getComponent } from "../helper";
import './Page.scss';

const Page = ({ page }) => {
    const [pageBuilder, setPageBuilder] = useState([]);

    useEffect(() => {
        sanityClient.fetch(
            `*[_type == 'page' && _id == '${page}']{
                _id,
                _type,
                pageName,
                pageBuilder
            }`
        )
            .then(data => setPageBuilder(data[0]?.pageBuilder || []))
            .catch(error => console.error(error));
    }, [page]);

    return <div className="Page">{pageBuilder.map(content => getComponent(content))}</div>
}

export default Page;