import React from "react";
import Image from "../Image/Image";
import './Content.scss';

const Content = ({ textEditor }) => {

    const getText = text => text.children.map(c => {
        let text = c.text.split('\n');
        text = text.map((t, i) => i < text.length - 1 ? <>{t}<br /></> : t)
        return <span key={c._key} className={getMarks(c.marks)}>{text}</span>;
    })

    const getMarks = marks => marks.length > 0 ? marks.join(' ') : '';

    const getStyle = text => {
        if( text._type === 'image') {
            return <Image key={text._key} {...text} />
        }
        switch (text.style) {
            case 'h1':
                return <h1 key={text._key}>{getText(text)}</h1>
            case 'h2':
                return <h2 key={text._key}>{getText(text)}</h2>
            case 'h3':
                return <h3 key={text._key}>{getText(text)}</h3>
            case 'h4':
                return <h4 key={text._key}>{getText(text)}</h4>
            case 'h5':
                return <h5 key={text._key}>{getText(text)}</h5>
            case 'h6':
                return <h6 key={text._key}>{getText(text)}</h6>
            case 'blockquote':
                return <p key={text._key}>"{getText(text)}"</p>
            case 'normal':
                return <p key={text._key}>{getText(text)}</p>
            default:
                return <div  key={text._key}>{getText(text)}</div>;
        }
    }

    return (
        <div className="Content">
            {textEditor.map(text => getStyle(text))}
        </div>
    )
}

export default Content;