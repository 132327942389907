import React from 'react';
import { getComponent } from '../helper';
import './Banner.scss';

const Banner = ({ color, sectionBuilder }) => (
    <div className={`Banner Banner--${color}`}>
        <div className='Banner__innerWrapper'>
            {sectionBuilder.map(content => getComponent(content))}
        </div>
    </div>
)

export default Banner;