import Box from "./Box/Box"
import CallToAction from "./CallToAction/CallToAction"
import Content from "./Content/Content"
import Hero from "./Hero/Hero"
import Image from "./Image/Image"
import Section from "./Section/Section"
import Video from "./Video/Video"
import Banner from "./Banner/Banner"

export const getComponent = content => {
    switch (content._type) {
        case 'hero':
            return <Hero key={content._key} content={content} />
        case 'callToAction':
            return <CallToAction key={content._key} {...content} />
        case 'section':
            return <Section key={content._key} {...content} />
        case 'box':
            return <Box key={content._key} {...content} />
        case 'content':
            return <Content key={content._key} {...content} />
        case 'img':
            return <Image key={content._key} {...content} />
        case 'video':
            return <Video key={content._key} {...content} />;
        case 'banner':
            return <Banner key={content._key} {...content} />;
        default:
            return <p key={content._key}>Something went wrong, could not find what you are looking for.</p>
    }
}