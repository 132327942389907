import React from "react";
import ImageUrlBuilder from "@sanity/image-url";
import sanityClient from '../../client';
import './Image.scss';

const Image = (props) => {
    const builder = ImageUrlBuilder(sanityClient);

    function urlFor(source) {
        return builder.image(source);
    }

    return (!!props._type && (props._type === 'img' || props._type === 'image')) && <img className="Image" src={urlFor(props).width().url()} alt={!!props ? props.attribution : 'Saknar text'} />
}

export default Image;