import React, { useEffect, useState } from "react";
import * as FaIcons from 'react-icons/fa';
import * as MdIcons from 'react-icons/md'
import * as HiIcons from 'react-icons/hi';
import * as FiIcons from 'react-icons/fi';
import * as BsIcons from 'react-icons/bs';
import sanityClient from '../../client';
import './Box.scss';

const Box = ({ heading, text, page, color, icon }) => {

    const [link, setLink] = useState(null);

    useEffect(() => {
        (!!page && page._ref) && sanityClient.fetch(
            `*[_type == 'page' && _id == '${page._ref}']{
                _id,
                _type,
                pathName
            }`
        )
            .then(data => (data[0] && data[0].pathName) && setLink(data[0].pathName))
            .catch(error => console.error(error));
    }, [page]);

    const getIcon = (icon) => {
        let Icon = null;
        switch (icon.provider) {
            case "fa":
                Icon = FaIcons[icon.name];
                break;
            case "mdi":
                Icon = MdIcons[icon.name];
                break;
            case "hi":
                Icon = HiIcons[icon.name];
                break;
            case "fi":
                Icon = FiIcons[icon.name];
                break;
            case "bs":
                Icon = BsIcons[icon.name];
                break;
            default:
                return;
        }
        return Icon ? <Icon /> : null;
    }

    const insideElement = (
        <div className="Box">
            <div className={`Box__circle Box__circle--${color}`}>
                {icon && <div className="Box__icon">{getIcon(icon)}</div>}
            </div>
            <div className="Box__content">
                <h3>{heading}</h3>
                <p>{text}</p>
            </div>
        </div >
    )

    return (
        !!link ? (
            <a href={link}>
                {insideElement}
            </a>
        ) : insideElement
    )
}

export default Box;