import React, { useEffect, useState } from "react";
import { BsLinkedin, BsTelephoneFill } from 'react-icons/bs';
import { MdEmail } from 'react-icons/md';
import sanityClient from '../../client';

import './Footer.scss';

const Footer = () => {
    const [footer, setFooter] = useState(null);

    useEffect(() => {
        sanityClient.fetch(
            `*[_type == 'siteSettings']{
                footer
            }[0].footer`
        )
            .then(data => setFooter(data))
            .catch(error => console.error(error));
    }, []);

    return (
        !!footer && <div className="Footer">
            <h3 className="Footer__title">Kontakta mig</h3>
            <div className="Footer__container">
                {footer.phone && (
                    <div className="Footer__paragraph">
                        <BsTelephoneFill />
                        <a href={`tel:${footer.phone}`}>{footer.phone}</a>
                    </div>
                )}
                {footer.email && (
                    <div className="Footer__paragraph">
                        <MdEmail />
                        <a href={`mailto:${footer.email}`}>{footer.email}</a>
                    </div>
                )}
                {footer.linkedin && (
                    <div className="Footer__paragraph">
                        <BsLinkedin />
                        <a href={footer.linkedin}>LinkedIn</a>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Footer;