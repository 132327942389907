import React, { useEffect, useState } from 'react';
import { createPath, Link, useLocation } from 'react-router-dom';
import './Header.scss';

const Header = ({ links }) => {

    const [headerColor, setHeaderColor ] = useState('transparent');

    const listenToScrollEvent = () => {
        window.scrollY > 100 ? window.scrollY < 200 ? setHeaderColor('half') : setHeaderColor('full') : setHeaderColor('transparent');
    }

    useEffect(() => {
        window.addEventListener('scroll', listenToScrollEvent);
    }, []);

    const path = useLocation().pathname;

    return (
        <div className={`Header Header--${headerColor}`}>
            <div><Link to="/"><h1 className="Header__title">Elisabeth Falkemo</h1></Link></div>
            <div className='Header__menu'>
                {links.map(link => <Link key={link._id} className={`Header__link${path.includes(link.pathName) ? '--active' : ''}`} to={createPath({ pathname: link.pathName })}>{link.pageName}</Link>)}
            </div>
        </div>
    )
}

export default Header;